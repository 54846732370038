import { Banner as OgpBanner } from '@opengovsg/design-system-react'
import { type BannerVariant } from '@opengovsg/design-system-react/build/main/theme/components/Banner'
import { BannerLevel } from '@prisma/client'
import { useMemo } from 'react'
import { trpc } from '~/utils/trpc'

export const Banner = () => {
  // Banner is rendered at the root, avoid Suspense
  const { data: banner } = trpc.banner.getLatest.useQuery(undefined, {
    refetchInterval: 30 * 1000, // refetch every 30 seconds
  })

  const variant: BannerVariant = useMemo(() => {
    if (!banner) return 'info'
    if (banner.level === BannerLevel.ERROR) return 'error'
    if (banner.level === BannerLevel.WARN) return 'warn'
    return 'info' // catch-all
  }, [banner])

  if (!banner) return <></>

  return (
    <OgpBanner isDismissable={false} variant={variant}>
      {banner.text}
    </OgpBanner>
  )
}
