import { extendTheme } from '@chakra-ui/react'
import { theme as ogpDsTheme } from '@opengovsg/design-system-react'
import { components } from './components'
import { textStyles } from './textStyles'

export const theme = extendTheme(ogpDsTheme, {
  components: {
    ...ogpDsTheme.components,
    ...components,
  },
  textStyles,
})
