import { Button } from './Button'
import { FormLabel } from './FormLabel'
import { Input } from './Input'
import { SingleSelect } from './SingleSelect'

export const components = {
  Button,
  Input,
  SingleSelect,
  FormLabel,
}
