import '@fontsource/ibm-plex-mono' // Import if using code textStyles.
import 'inter-ui/inter.css' // Strongly recommended.

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from '@opengovsg/design-system-react'
import type { AppProps, AppType } from 'next/app'
import { type NextPageWithLayout } from '~/lib/types'
import { theme } from '~/theme'
import { trpc } from '~/utils/trpc'
import Suspense from '~/components/Suspense/Suspense'
import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary'
import { Skeleton } from '@chakra-ui/react'
import { AuthenticatedLayout } from '~/templates/layouts/AuthenticatedLayout'
import { Banner } from '~/components/Banner'
import { env } from '~/env.mjs'
import { initDatadog } from '~/lib/datadog'

type AppPropsWithAuthAndLayout = AppProps & {
  Component: NextPageWithLayout
}

if (env.NEXT_PUBLIC_DEPLOY_ENV) {
  initDatadog()
}

const MyApp = ((props: AppPropsWithAuthAndLayout) => {
  return (
    <ThemeProvider theme={theme}>
      <Banner />
      <ErrorBoundary>
        <Suspense fallback={<Skeleton width="100vw" height="100vh" />}>
          <ChildWithLayout {...props} />
          {process.env.NODE_ENV !== 'production' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  )
}) as AppType

// This is needed so suspense will be triggered for anything within the LayoutComponents which uses useSuspenseQuery
const ChildWithLayout = ({
  Component,
  pageProps,
}: AppPropsWithAuthAndLayout) => {
  // Use page layout, default to authenticated layout
  if (!AuthenticatedLayout) throw new Error('Missing authenticated layout') // never
  const getLayout = Component.getLayout ?? AuthenticatedLayout

  return <>{getLayout(<Component {...pageProps} />)}</>
}

export default trpc.withTRPC(MyApp)
