import { RestrictedGovtMasthead } from '@opengovsg/design-system-react'
import Head from 'next/head'
import { Box } from '@chakra-ui/react'
import { type NextPageWithLayout } from '~/lib/types'
import { env } from '~/env.mjs'

// Public
export const DefaultLayout: NextPageWithLayout['getLayout'] = (page) => {
  return (
    <>
      <Head>
        <title>
          {env.NEXT_PUBLIC_DEPLOY_ENV === 'production'
            ? 'PX'
            : `PX (${env.NEXT_PUBLIC_DEPLOY_ENV ?? 'dev'})`}
        </title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="robots" content="noindex, nofollow" />
      </Head>

      <Box bgColor="#FAFBFC" minH="$100vh">
        <Box as="main" w="full" maxW="430px" mx="auto">
          <RestrictedGovtMasthead />
          {page}
        </Box>
      </Box>
    </>
  )
}
