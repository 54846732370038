import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const lg = {
  h: 14,
  fontSize: '1rem',
}

const sizes = {
  lg: definePartsStyle({
    field: lg,
  }),
}

export const Input = defineMultiStyleConfig({
  sizes,
  defaultProps: {
    size: 'lg',
  },
})
