import { DefaultLayout } from './DefaultLayout'
import { useMe } from '~/hooks/auth'
import { type NextPageWithLayout } from '~/lib/types'

export const AuthenticatedLayout: NextPageWithLayout['getLayout'] = (page) => {
  // Attempt to fetch the current user to verify that the user has an authenticated session.
  // If this calls fails, the user does not have a session and is redirected via ErrorBoundary.
  useMe()

  if (!DefaultLayout) throw new Error('Missing default layout')
  return DefaultLayout(page)
}
