import { useRouter } from 'next/router'
import { ErrorPage } from './ErrorPage'

export const UnexpectedErrorCard = () => {
  const router = useRouter()
  return (
    <ErrorPage
      header="Oops, an unexpected error occurred"
      buttonText="Back"
      onButtonClick={() => router.back()}
    />
  )
}
