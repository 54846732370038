import { useRouter } from 'next/router'
import { ErrorPage, type ErrorPageProps } from './ErrorPage'
import { routes } from '~/constants/routes'

export const NotFoundCard = (props: ErrorPageProps) => {
  const router = useRouter()
  return (
    <ErrorPage
      header="Sorry, we couldn't find that page"
      description="Please check the URL."
      buttonText={'Back to Home'}
      onButtonClick={() => router.push(routes.dashboard())}
      {...props}
    />
  )
}
