import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const sizes = defineStyle({
  lg: {
    h: 14,
  },
})

export const Button = defineStyleConfig({
  sizes,
  defaultProps: {
    size: 'lg',
  },
})
