import { anatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { Input as PXInput } from './Input'

export const comboboxParts = anatomy('combobox').parts(
  'list',
  'item',
  'itemDescription',
  'highlight',
  'icon',
  'emptyItem'
)

export const parts = anatomy('singleselect')
  .parts(...comboboxParts.keys)
  .extend('field', 'selected')

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const sizes = {
  // Make SingleSelect height 56px
  md: definePartsStyle({
    icon: {
      mt: '12px',
    },
    field: PXInput.sizes?.lg.field,
  }),
}

export const SingleSelect = defineMultiStyleConfig({
  sizes,
})
