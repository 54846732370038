import { datadogRum } from '@datadog/browser-rum'
import { env } from '~/env.mjs'

export const initDatadog = () => {
  datadogRum.init({
    applicationId: '2de58a68-b971-43b3-9b13-46c90dc8d074',
    clientToken: 'pub200eb395066b62afe410d4236d3c3cf0',
    site: 'datadoghq.com',
    service: 'pixie',
    env: env.NEXT_PUBLIC_DEPLOY_ENV,
    version: env.NEXT_PUBLIC_GIT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

export const setDatadogUser = (id: number) => {
  datadogRum.setUser({
    id: id.toString(),
  })
}
