export const routes = {
  dashboard: () => `/dashboard`,
  unknownPatient: () => `/unknown-patient`,

  faRequest: (id: number) => ({
    apply: () => `/fa-request/apply/${id}`,
    manageDraft: () => `${routes.faRequest(id).apply()}/manage-draft`,
    selectInstitution: () =>
      `${routes.faRequest(id).apply()}/select-institution`,
    retrieveInfo: () => `${routes.faRequest(id).apply()}/retrieve-info`,
    existingApplication: () =>
      `${routes.faRequest(id).apply()}/existing-application`,
    pendingApplication: () =>
      `${routes.faRequest(id).apply()}/pending-application`,
    myInfo: () => `${routes.faRequest(id).apply()}/my-info`,
    updateFamily: () => `${routes.faRequest(id).apply()}/update-family`,
    updateFamilyMember: (memberId: number) =>
      `${routes.faRequest(id).apply()}/update-family/${memberId}`,
    uploadDocuments: () => `${routes.faRequest(id).apply()}/upload-documents`,
    verifyContact: () => `${routes.faRequest(id).apply()}/verify-contact`,
    otp: () => `${routes.faRequest(id).apply()}/otp`,
    success: () => `${routes.faRequest(id).apply()}/success`,
  }),
}
