import { HStack, Spacer } from '@chakra-ui/react'
import { AppLogo } from './AppLogo'

export const Navbar = () => {
  return (
    <HStack h={16} p={4} w="full">
      <AppLogo />
      <Spacer />
    </HStack>
  )
}
