import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
  marginBottom: '0.5rem',
  marginRight: '0.5rem',
})

export const FormLabel = defineStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'md',
  },
})
