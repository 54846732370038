import { z } from 'zod'

// Coerces a string to true if it's "true", false if "false".
const _coerceBoolean = z
  .string()
  // only allow "true" or "false"
  .refine((s) => s === 'true' || s === 'false')
  // transform to boolean
  .transform((s) => s === 'true')
  // make sure tranform worked
  .pipe(z.boolean())

/**
 * Specify your client-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  NEXT_PUBLIC_DEPLOY_ENV: z.enum(['staging', 'uat', 'production']).optional(),
  NEXT_PUBLIC_GIT_SHA: z.string().optional(),
})

/**
 * Specify your server-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars.
 */
const server = z
  .object({
    // Tools
    DATABASE_URL: z.string().url(),
    NODE_ENV: z
      .enum(['development', 'test', 'production'])
      .default('development'),
    OTP_EXPIRY: z.coerce.number().positive().optional().default(600),
    SESSION_SECRET: z.string().min(32),
    DEPLOY_ENV: z.enum(['staging', 'uat', 'production']).optional(),
    // Pixie
    BASE_URL: z.string().url(),
    CARE360_BASE_URL: z.string().url(),
    CARE360_API_KEY: z.string(),
    TWILIO_ACCOUNT_SID: z.string(),
    TWILIO_API_KEY: z.string(),
    TWILIO_API_SECRET: z.string(),
    TWILIO_MESSAGING_SID: z.string(),
    TWILIO_VERIFY_SID: z.string(),
    REDIS_URL: z.string(),
    SGID_CLIENT_ID: z.string(),
    SGID_CLIENT_SECRET: z.string(),
    SGID_PRIVATE_KEY: z.string(),
    S3_DOCUMENTS_BUCKET: z.string(),
    S3_QUARANTINE_BUCKET: z.string(),
    POSTMAN_API_KEY: z.string(),
    VIRUS_SCANNER_LAMBDA: z.string(),
  })
  .merge(client)

/**
 * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
 * middlewares) or client-side so we need to destruct manually.
 * Intellisense should work due to inference.
 *
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  // Server-side env vars
  NODE_ENV: process.env.NODE_ENV,
  DATABASE_URL: process.env.DATABASE_URL,
  OTP_EXPIRY: process.env.OTP_EXPIRY,
  SESSION_SECRET: process.env.SESSION_SECRET,
  DEPLOY_ENV: process.env.DEPLOY_ENV,
  // Pixie
  BASE_URL: process.env.BASE_URL,
  CARE360_BASE_URL: process.env.CARE360_BASE_URL,
  CARE360_API_KEY: process.env.CARE360_API_KEY,
  TWILIO_ACCOUNT_SID: process.env.TWILIO_ACCOUNT_SID,
  TWILIO_API_KEY: process.env.TWILIO_API_KEY,
  TWILIO_API_SECRET: process.env.TWILIO_API_SECRET,
  TWILIO_MESSAGING_SID: process.env.TWILIO_MESSAGING_SID,
  TWILIO_VERIFY_SID: process.env.TWILIO_VERIFY_SID,
  REDIS_URL: process.env.REDIS_URL,
  SGID_CLIENT_ID: process.env.SGID_CLIENT_ID,
  SGID_CLIENT_SECRET: process.env.SGID_CLIENT_SECRET,
  SGID_PRIVATE_KEY: process.env.SGID_PRIVATE_KEY,
  S3_DOCUMENTS_BUCKET: process.env.S3_DOCUMENTS_BUCKET,
  S3_QUARANTINE_BUCKET: process.env.S3_QUARANTINE_BUCKET,
  POSTMAN_API_KEY: process.env.POSTMAN_API_KEY,
  VIRUS_SCANNER_LAMBDA: process.env.VIRUS_SCANNER_LAMBDA,
  // Client-side env vars
  NEXT_PUBLIC_DEPLOY_ENV: process.env.NEXT_PUBLIC_DEPLOY_ENV,
  NEXT_PUBLIC_GIT_SHA: process.env.NEXT_PUBLIC_GIT_SHA,
}

// Don't touch the part below
// --------------------------
/** @typedef {z.input<typeof server>} MergedInput */
/** @typedef {z.infer<typeof server>} MergedOutput */
/** @typedef {z.SafeParseReturnType<MergedInput, MergedOutput>} MergedSafeParseReturn */

// @ts-expect-error Types are wonky from refinement
let env = /** @type {MergedOutput} */ (process.env)

if (!!process.env.SKIP_ENV_VALIDATION == false) {
  const isServer = typeof window === 'undefined'

  const parsed = /** @type {MergedSafeParseReturn} */ (
    isServer
      ? server.safeParse(processEnv) // on server we can validate all env vars
      : client.safeParse(processEnv) // on client we can only validate the ones that are exposed
  )

  if (parsed.success === false) {
    console.error(
      '❌ Invalid environment variables:',
      parsed.error.flatten().fieldErrors
    )
    throw new Error('Invalid environment variables')
  }

  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== 'string') return undefined
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith('NEXT_PUBLIC_'))
        throw new Error(
          process.env.NODE_ENV === 'production'
            ? '❌ Attempted to access a server-side environment variable on the client'
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`
        )
      return target[/** @type {keyof typeof target} */ (prop)]
    },
  })
}

export { env }
