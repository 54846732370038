import { type StackProps, VStack } from '@chakra-ui/react'

export const PageContainer = ({ children, ...rest }: StackProps) => {
  // console.log(`${calc('$100vh').subtract('32px').toString()}`)
  return (
    <VStack
      w="full"
      bgRepeat="no-repeat"
      bgSize="100% auto"
      bgPos="top"
      minH="calc(var(--chakra-vh) - 32px)"
      spacing={0}
      pb={6}
      {...rest}
    >
      {children}
    </VStack>
  )
}
