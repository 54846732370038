import { useEffect } from 'react'
import { setDatadogUser } from '~/lib/datadog'
import { trpc } from '~/utils/trpc'

export const useMe = () => {
  const [me] = trpc.me.get.useSuspenseQuery()

  // Set RUM user
  useEffect(() => {
    setDatadogUser(me.id)
  }, [me.id])

  return me
}
