import { useRouter } from 'next/router'
import { trpc } from '~/utils/trpc'

export const useLogout = () => {
  const router = useRouter()
  return trpc.auth.logout.useMutation({
    onSuccess: () => {
      router.push('/') // redirect to index
    },
  })
}
