import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { POST_LOGIN_REDIRECT } from '~/constants/params'
import { trpc } from '~/utils/trpc'

export const useVerifyCode = () => {
  const router = useRouter()
  const toast = useToast()

  return trpc.auth.callback.useMutation({
    onSuccess: ({ isLoggedIn }) => {
      if (isLoggedIn) {
        toast({
          description: 'Logged in successfully',
          status: 'success',
        })

        let url = '/dashboard' // default

        // Check for post-login redirection path
        if (window.sessionStorage) {
          const postLoginRedirect =
            window.sessionStorage.getItem(POST_LOGIN_REDIRECT)
          if (postLoginRedirect && postLoginRedirect !== null) {
            url = postLoginRedirect
            window.sessionStorage.removeItem(POST_LOGIN_REDIRECT)
          }
        }

        router.push(url)
      } else {
        toast({
          description: 'Failed to login',
          status: 'error',
        })
        router.push('/')
      }
    },
  })
}
