import { Button, Text, VStack } from '@chakra-ui/react'
import { Navbar } from '../Navbar'
import { PageContainer } from '../PageContainer/PageContainer'
import { type ReactNode } from 'react'

export interface ErrorPageProps {
  header?: string
  description?: string
  buttonText?: ReactNode
  onButtonClick?: VoidFunction
}

export const ErrorPage = ({
  header,
  description,
  buttonText,
  onButtonClick,
}: ErrorPageProps) => {
  return (
    <PageContainer spacing={3} bg="base.canvas.default">
      <Navbar />

      <VStack spacing={6} p={6} align="flex-start">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="43"
          viewBox="0 0 48 43"
          fill="none"
        >
          <path
            d="M25.5991 16.6946H22.3215L22.5925 28.9413H25.3197L25.5991 16.6946ZM23.9561 34.2261C24.9555 34.2261 25.8109 33.3961 25.8194 32.3629C25.8109 31.3466 24.9555 30.5166 23.9561 30.5166C22.9228 30.5166 22.0844 31.3466 22.0928 32.3629C22.0844 33.3961 22.9228 34.2261 23.9561 34.2261Z"
            fill="#2E4D8C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.086 36.5221L26.2196 3.84443C25.2331 2.13579 22.7669 2.13579 21.7804 3.84443L2.91397 36.5221C1.92749 38.2307 3.16059 40.3665 5.13356 40.3665H42.8665C44.8394 40.3665 46.0725 38.2307 45.086 36.5221ZM28.4392 2.56295C26.4662 -0.854318 21.5338 -0.854317 19.5608 2.56295L0.694388 35.2406C-1.27857 38.6579 1.18763 42.9295 5.13356 42.9295H42.8665C46.8124 42.9295 49.2786 38.6579 47.3056 35.2406L28.4392 2.56295Z"
            fill="#2E4D8C"
          />
        </svg>

        <Text textStyle="h4">
          {header ?? 'Oops, an unexpected error occurred!'}
        </Text>

        {description && <Text>{description}</Text>}

        {onButtonClick && (
          <Button onClick={onButtonClick} w="full">
            {buttonText}
          </Button>
        )}
      </VStack>
    </PageContainer>
  )
}
